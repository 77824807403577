import { Box, Link, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { colors } from '../utils/theme'
import { Container } from './Container'
import { Headline } from './Headline'
import { cx } from '../utils/cx'
import { richText } from '../utils/richText'

const useStyles = makeStyles(theme => ({
  withStrong: {
    fontWeight: 600,
    '& strong': {
      fontWeight: 600
    }
  },
  image: {
    width: '100%',
    height: 'auto'
  },
  cAnimation: {
    animation: `$animateCForward 1.4s ${theme.transitions.easing.easeInOut}`,
    animationFillMode: 'forwards'
  },
  cAnimationBack: {
    animation: `$animateCBack 1.4s ${theme.transitions.easing.easeInOut}`,
    animationFillMode: 'forwards'
  },
  '@keyframes animateCForward': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(270deg)' }
  },
  '@keyframes animateCBack': {
    '0%': { transform: 'rotate(270deg)' },
    '100%': { transform: 'rotate(0deg)' }
  },
  j: {
    '& div': {
      transformOrigin: '100% 5%'
    }
  },
  jAnimation: {
    '& div': {
      animation: `$animateJForward 1s ${theme.transitions.easing.easeInOut}`,
      animationFillMode: 'forwards'
    }
  },
  jAnimationBack: {
    '& div': {
      animation: `$animateJBack 1s ${theme.transitions.easing.easeInOut}`,
      animationFillMode: 'forwards'
    }
  },
  '@keyframes animateJForward': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(-30deg)' }
  },
  '@keyframes animateJBack': {
    '0%': { transform: 'rotate(-30deg)' },
    '100%': { transform: 'rotate(0deg)' }
  },
  intrinsic: {
    '& img': {
      height: 'intrinsic'
    }
  },
  iAnimation: {
    '& img': {
      animation: `$animateIForward 1s ${theme.transitions.easing.easeInOut}`,
      animationFillMode: 'forwards'
    }
  },
  iAnimationBack: {
    '& img': {
      animation: `$animateIBack 1s ${theme.transitions.easing.easeInOut}`,
      animationFillMode: 'forwards'
    }
  },
  '@keyframes animateIForward': {
    '0%': { transform: 'rotate(0)' },
    '100%': { transform: 'rotate(350deg)' }
  },
  '@keyframes animateIBack': {
    '0%': { transform: 'rotate(350deg)' },
    '100%': { transform: 'rotate(0)' }
  },
  aAnimation: {
    '& div': {
      animation: `$animateAForward .8s ${theme.transitions.easing.easeInOut}`,
      animationFillMode: 'forwards'
    }
  },
  aAnimationBack: {
    '& div': {
      animation: `$animateABack .8s ${theme.transitions.easing.easeInOut}`,
      animationFillMode: 'forwards'
    }
  },
  '@keyframes animateAForward': {
    '0%': { transform: 'translateY(0)' },
    '100%': { transform: 'translateY(-80px)' }
  },
  '@keyframes animateABack': {
    '0%': { transform: 'translateY(-80px)' },
    '100%': { transform: 'translateY(0)' }
  },
  l: {
    transform: 'rotate(90deg)',
    '& img': {
      height: 'intrinsic'
    }
  },
  lAnimation: {
    animation: `$animateLForward 0.8s ${theme.transitions.easing.easeInOut}`,
    animationFillMode: 'forwards'
  },
  lAnimationBack: {
    animation: `$animateLBack 0.8s ${theme.transitions.easing.easeIn}`,
    animationFillMode: 'forwards'
  },
  '@keyframes animateLForward': {
    '0%': { transform: 'rotate(90deg)' },
    '100%': { transform: 'translateY(26%) rotate(0deg) ' }
  },
  '@keyframes animateLBack': {
    '0%': { transform: 'translateY(26%) rotate(0deg) ' },
    '100%': { transform: 'rotate(90deg)' }
  },
  dotAnimation: {
    animation: `$animateDotForward 0.8s ${theme.transitions.easing.easeInOut}`,
    animationFillMode: 'forwards'
  },
  dotAnimationBack: {
    animation: `$animateDotBack 0.8s ${theme.transitions.easing.easeIn}`,
    animationFillMode: 'forwards'
  },
  '@keyframes animateDotForward': {
    '0%': { transform: 'translate(0)' },
    '100%': { transform: 'translate(-80px, 70px)' }
  },
  '@keyframes animateDotBack': {
    '0%': { transform: 'translate(-80px, 70px)' },
    '100%': { transform: 'translate(0)' }
  }
}))

const isIn = a => a === 'in'
const isOut = a => a === 'out'
export function MobileMainHome ({ headerHeight, headline, readMore }) {
  const classes = useStyles()
  const [animations, setAnimations] = useState({})
  // useEffect(() => {
  //   randomiseAnimation(setAnimations, {
  //     quickStart: true,
  //     times: [2000, 2000, 3000, 3000, 4000]
  //   })
  // }, [])

  return (
    <>
      <Box height={`${headerHeight}px`} width='100%' />
      <Box bgcolor={colors.black} pb='35px' pt='35px' overflow='hidden'>
        <Box display='flex' pl='24px' justifyContent='space-between'>
          <Box
            width='43%'
            onAnimationEnd={() =>
              setAnimations(prev => {
                return {
                  ...prev,
                  c: prev.c === 'in' ? 'out' : false
                }
              })
            }
            onClick={() => {
              setAnimations(prev => ({ ...prev, c: 'in' }))
            }}
            className={cx(
              isIn(animations.c) && classes.cAnimation,
              isOut(animations.c) && classes.cAnimationBack
            )}
          >
            <img alt='' src='/images/UCLetters.svg' className={classes.image} />
          </Box>
          <Box
            width='45%'
            mr='-24px'
            onAnimationEnd={() =>
              setAnimations(prev => {
                return {
                  ...prev,
                  j: prev.j === 'in' ? 'out' : false
                }
              })
            }
            onClick={() => {
              setAnimations(prev => ({ ...prev, j: 'in' }))
            }}
            className={cx(
              classes.j,
              isIn(animations.j) && classes.jAnimation,
              isOut(animations.j) && classes.jAnimationBack
            )}
          >
            <div>
              <img
                alt=''
                src={`/images/JLetter.svg`}
                className={classes.image}
              />
            </div>
          </Box>
        </Box>
        <Box
          display='flex'
          px='24px'
          justifyContent='space-between'
          alignItems='flex-start'
          maxHeight='180px'
        >
          <Box
            width='35%'
            display='flex'
            mt='60px'
            onAnimationEnd={() =>
              setAnimations(prev => {
                return {
                  ...prev,
                  l: prev.l === 'in' ? 'out' : false
                }
              })
            }
            onClick={() => {
              setAnimations(prev => ({ ...prev, l: 'in' }))
            }}
            className={cx(
              classes.l,
              isIn(animations.l) && classes.lAnimation,
              isOut(animations.l) && classes.lAnimationBack
            )}
          >
            <img alt='' src={`/images/LLetter.svg`} className={classes.image} />
          </Box>
          <Box
            width='14%'
            mt='30px'
            onClick={() => {
              setAnimations(prev => ({ ...prev, l: 'in' }))
            }}
            className={cx(
              isIn(animations.l) && classes.dotAnimation,
              isOut(animations.l) && classes.dotAnimationBack
            )}
          >
            <img
              alt=''
              src='data:image/svg+xml;base64,ICA8c3ZnCiAgICB2ZXJzaW9uPScxLjEnCiAgICBpZD0nR3JvdXBfMTInCiAgICB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnCiAgICB4PScwcHgnCiAgICB5PScwcHgnCiAgICB2aWV3Qm94PScwIDAgMTA3LjcgMTA3LjcnCiAgPgogICAgPHBhdGgKICAgICAgaWQ9J1BhdGhfMTgnCiAgICAgIGNsYXNzTmFtZT0nc3QwJwogICAgICBmaWxsPScjRUJFQkUzJwogICAgICBkPSdNNTMuOSwxMDcuN2MyOS43LDAsNTMuOS0yNC4xLDUzLjktNTMuOWMwLDAsMCwwLDAsMEMxMDcuNywyNC4xLDgzLjYsMCw1My45LDBsMCwwCkMyNC4xLDAsMCwyNC4xLDAsNTMuOVMyNC4xLDEwNy43LDUzLjksMTA3LjdMNTMuOSwxMDcuNycKICAgIC8+CiAgPC9zdmc+Cgo='
              className={classes.image}
            />
          </Box>
          <Box
            width='33%'
            onAnimationEnd={() =>
              setAnimations(prev => {
                return {
                  ...prev,
                  a: prev.a === 'in' ? 'out' : false
                }
              })
            }
            onClick={() => {
              setAnimations(prev => ({ ...prev, a: 'in' }))
            }}
            className={cx(
              classes.j,
              isIn(animations.a) && classes.aAnimation,
              isOut(animations.a) && classes.aAnimationBack
            )}
          >
            <div>
              <img
                alt=''
                src='data:image/svg+xml;base64,ICA8c3ZnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zycgdmlld0JveD0nMCAwIDE4Mi4xNjMgMTgyLjE2Myc+CiAgICA8ZGVmcz4KICAgICAgPGNsaXBQYXRoIGlkPSdjbGlwLXBhdGgnPgogICAgICAgIDxwYXRoCiAgICAgICAgICBpZD0nUGF0aF8yMicKICAgICAgICAgIGRhdGEtbmFtZT0nUGF0aCAyMicKICAgICAgICAgIGQ9J00wLDg4Ljk3MkgxODIuMTYzVi05My4xOTFIMFonCiAgICAgICAgICB0cmFuc2Zvcm09J3RyYW5zbGF0ZSgwIDkzLjE5MSknCiAgICAgICAgICBmaWxsPSdub25lJwogICAgICAgIC8+CiAgICAgIDwvY2xpcFBhdGg+CiAgICA8L2RlZnM+CiAgICA8ZyBpZD0nR3JvdXBfMTYnIGRhdGEtbmFtZT0nR3JvdXAgMTYnIHRyYW5zZm9ybT0ndHJhbnNsYXRlKDAgOTMuMTkxKSc+CiAgICAgIDxnCiAgICAgICAgaWQ9J0dyb3VwXzE1JwogICAgICAgIGRhdGEtbmFtZT0nR3JvdXAgMTUnCiAgICAgICAgdHJhbnNmb3JtPSd0cmFuc2xhdGUoMCAtOTMuMTkxKScKICAgICAgICBjbGlwUGF0aD0ndXJsKCNjbGlwLXBhdGgpJwogICAgICA+CiAgICAgICAgPGcKICAgICAgICAgIGlkPSdHcm91cF8xNCcKICAgICAgICAgIGRhdGEtbmFtZT0nR3JvdXAgMTQnCiAgICAgICAgICB0cmFuc2Zvcm09J3RyYW5zbGF0ZSgwLjAwMSAwLjAwNCknCiAgICAgICAgPgogICAgICAgICAgPHBhdGgKICAgICAgICAgICAgaWQ9J1BhdGhfMjEnCiAgICAgICAgICAgIGRhdGEtbmFtZT0nUGF0aCAyMScKICAgICAgICAgICAgZD0nTTg4Ljk3Miw4OC45N1YtOTMuMTg5SC05My4xOTFWODguOTdoNTUuNDJWMzguMjM5SDM0Ljg5NFY4OC45N1pNMzMuNy0zMC4zMjVBMzUuMTQyLDM1LjE0MiwwLDAsMS0xLjQ0LDQuODEzLDM1LjE0MSwzNS4xNDEsMCwwLDEtMzYuNTgyLTMwLjMyNSwzNS4xNDIsMzUuMTQyLDAsMCwxLTEuNDQtNjUuNDY5LDM1LjE0MywzNS4xNDMsMCwwLDEsMzMuNy0zMC4zMjUnCiAgICAgICAgICAgIHRyYW5zZm9ybT0ndHJhbnNsYXRlKDkzLjE5MSA5My4xODkpJwogICAgICAgICAgICBmaWxsPScjODJjZGQ5JwogICAgICAgICAgLz4KICAgICAgICA8L2c+CiAgICAgIDwvZz4KICAgIDwvZz4KICA8L3N2Zz4K'
                className={classes.image}
              />
            </div>
          </Box>
        </Box>
        <Box display='flex' px='24px' justifyContent='space-between'>
          <Box
            width='25%'
            display='flex'
            mt='65px'
            onAnimationEnd={() =>
              setAnimations(prev => {
                return {
                  ...prev,
                  i: prev.i === 'in' ? 'out' : false
                }
              })
            }
            onClick={() => {
              setAnimations(prev => ({ ...prev, i: 'in' }))
            }}
            className={cx(
              classes.intrinsic,
              isIn(animations.i) && classes.iAnimation,
              isOut(animations.i) && classes.iAnimationBack
            )}
          >
            <img alt='' src={`/images/ILetter.svg`} className={classes.image} />
          </Box>
          <Box
            width='57%'
            display='flex'
            mt='-25px'
            className={classes.intrinsic}
          >
            <img
              alt=''
              src={`/images/InstitutoLatino.svg`}
              className={classes.image}
            />
          </Box>
        </Box>
      </Box>
      <Container>
        <Box pt='3rem' fontSize='12px'>
          {Boolean(headline) && (
            <Headline variant='h4' className={classes.withStrong}>
              {richText(headline)}
            </Headline>
          )}
          {Boolean(readMore) && (
            <Box pt='1.5rem' fontSize='17px'>
              <Link href='/about-us' underline='always'>
                {readMore}
              </Link>
            </Box>
          )}
        </Box>
      </Container>
    </>
  )
}
