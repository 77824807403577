import React, { useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { cx } from '../utils/cx'

const useStyles = makeStyles(theme => ({
  cAnimation: {
    animation: `$animateCForward 1.4s ${theme.transitions.easing.easeInOut}`,
    animationFillMode: 'forwards'
  },
  cAnimationBack: {
    animation: `$animateCBack 1.4s ${theme.transitions.easing.easeInOut}`,
    animationFillMode: 'forwards'
  },
  '@keyframes animateCForward': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(270deg)' }
  },
  '@keyframes animateCBack': {
    '0%': { transform: 'rotate(270deg)' },
    '100%': { transform: 'rotate(0deg)' }
  },
  j: {
    '& div': {
      transformOrigin: '100% 5%'
    }
  },
  jAnimation: {
    '& div': {
      animation: `$animateJForward 1s ${theme.transitions.easing.easeInOut}`,
      animationFillMode: 'forwards'
    }
  },
  jAnimationBack: {
    '& div': {
      animation: `$animateJBack 1s ${theme.transitions.easing.easeInOut}`,
      animationFillMode: 'forwards'
    }
  },
  '@keyframes animateJForward': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(-30deg)' }
  },
  '@keyframes animateJBack': {
    '0%': { transform: 'rotate(-30deg)' },
    '100%': { transform: 'rotate(0deg)' }
  },
  iAnimation: {
    '& div': {
      animation: `$animateIForward 1s ${theme.transitions.easing.easeInOut}`,
      animationFillMode: 'forwards'
    }
  },
  iAnimationBack: {
    '& div': {
      animation: `$animateIBack 1s ${theme.transitions.easing.easeInOut}`,
      animationFillMode: 'forwards'
    }
  },
  '@keyframes animateIForward': {
    '0%': { transform: 'translate(0)' },
    '100%': { transform: 'translate(-200px)' }
  },
  '@keyframes animateIBack': {
    '0%': { transform: 'translate(-200px)' },
    '100%': { transform: 'translate(0)' }
  },
  aAnimation: {
    '& div': {
      animation: `$animateAForward 1s ${theme.transitions.easing.easeInOut}`,
      animationFillMode: 'forwards'
    }
  },
  aAnimationBack: {
    '& div': {
      animation: `$animateABack 1s ${theme.transitions.easing.easeInOut}`,
      animationFillMode: 'forwards'
    }
  },
  '@keyframes animateAForward': {
    '0%': { transform: 'translateY(0)' },
    '100%': { transform: 'translateY(-150px)' }
  },
  '@keyframes animateABack': {
    '0%': { transform: 'translateY(-150px)' },
    '100%': { transform: 'translateY(0)' }
  },
  lAnimation: {
    '& .lLetter': {
      animation: `$animateLForward .4s ${theme.transitions.easing.easeIn}`,
      animationFillMode: 'forwards'
    },
    '& .dot': {
      animation: `$animateDotForward .5s ${theme.transitions.easing.easeOut} .3s`,
      animationFillMode: 'forwards'
    }
  },
  lAnimationBack: {
    '& .lLetter': {
      animation: `$animateLBack .4s ${theme.transitions.easing.easeIn}`,
      animationFillMode: 'forwards'
    },
    '& .dot': {
      animation: `$animateDotBack .5s`,
      animationFillMode: 'forwards'
    }
  },
  '@keyframes animateLForward': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(47deg)' }
  },
  '@keyframes animateLBack': {
    '0%': { transform: 'rotate(47deg)' },
    '100%': { transform: 'rotate(0deg)' }
  },
  '@keyframes animateDotForward': {
    '0%': { transform: 'translate(0)' },
    '100%': { transform: 'translate(80px, 100px)' }
  },
  '@keyframes animateDotBack': {
    '0%': { transform: 'translate(80px, 100px)' },
    '100%': { transform: 'translate(0)' }
  }
}))

const isIn = a => a === 'in'
const isOut = a => a === 'out'

export function AnimatedBanner () {
  const classes = useStyles()
  const [animations, setAnimations] = useState({})

  // useEffect(() => {
  //   randomiseAnimation(setAnimations)
  // }, [])

  return (
    <Box flexBasis='53%' pl='5%' pb='5%'>
      <Box height='100%' width='100%' position='relative'>
        <Box
          width='37%'
          position='absolute'
          onAnimationEnd={() => {
            setAnimations(prev => ({
              ...prev,
              c: prev.c === 'in' ? 'out' : false
            }))
          }}
          onMouseEnter={() => {
            !animations.c && setAnimations(prev => ({ ...prev, c: 'in' }))
          }}
          className={cx(
            isIn(animations.c) && classes.cAnimation,
            isOut(animations.c) && classes.cAnimationBack
          )}
        >
          <img
            fadeIn='false'
            loading='eager'
            alt=''
            src={`/images/UCLetters.svg`}
          />
        </Box>
        <Box
          width='45%'
          position='absolute'
          right={0}
          onAnimationEnd={() => {
            setAnimations(prev => ({
              ...prev,
              j: prev.j === 'in' ? 'out' : false
            }))
          }}
          onMouseEnter={() => {
            !animations.j && setAnimations(prev => ({ ...prev, j: 'in' }))
          }}
          className={cx(
            classes.j,
            isIn(animations.j) && classes.jAnimation,
            isOut(animations.j) && classes.jAnimationBack
          )}
        >
          <div>
            <img
              fadeIn='false'
              loading='eager'
              alt=''
              src={`/images/JLetter.svg`}
            />
          </div>
        </Box>
        <Box
          width='25%'
          position='absolute'
          right='42%'
          top='44%'
          onAnimationEnd={() => {
            setAnimations(prev => ({
              ...prev,
              i: prev.i === 'in' ? 'out' : false
            }))
          }}
          onMouseEnter={() => {
            !animations.i && setAnimations(prev => ({ ...prev, i: 'in' }))
          }}
          className={cx(
            classes.i,
            isIn(animations.i) && classes.iAnimation,
            isOut(animations.i) && classes.iAnimationBack
          )}
        >
          <div>
            <img
              fadeIn='false'
              loading='eager'
              alt=''
              src={`/images/ILetter.svg`}
            />
          </div>
        </Box>
        <Box
          width='25%'
          position='absolute'
          right={0}
          top='40%'
          onAnimationEnd={() => {
            setAnimations(prev => ({
              ...prev,
              a: prev.a === 'in' ? 'out' : false
            }))
          }}
          onMouseEnter={() => {
            !animations.a && setAnimations(prev => ({ ...prev, a: 'in' }))
          }}
          className={cx(
            classes.a,
            isIn(animations.a) && classes.aAnimation,
            isOut(animations.a) && classes.aAnimationBack
          )}
        >
          <div>
            <img
              fadeIn='false'
              loading='eager'
              alt=''
              src='data:image/svg+xml;base64,ICA8c3ZnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zycgdmlld0JveD0nMCAwIDE4Mi4xNjMgMTgyLjE2Myc+CiAgICA8ZGVmcz4KICAgICAgPGNsaXBQYXRoIGlkPSdjbGlwLXBhdGgnPgogICAgICAgIDxwYXRoCiAgICAgICAgICBpZD0nUGF0aF8yMicKICAgICAgICAgIGRhdGEtbmFtZT0nUGF0aCAyMicKICAgICAgICAgIGQ9J00wLDg4Ljk3MkgxODIuMTYzVi05My4xOTFIMFonCiAgICAgICAgICB0cmFuc2Zvcm09J3RyYW5zbGF0ZSgwIDkzLjE5MSknCiAgICAgICAgICBmaWxsPSdub25lJwogICAgICAgIC8+CiAgICAgIDwvY2xpcFBhdGg+CiAgICA8L2RlZnM+CiAgICA8ZyBpZD0nR3JvdXBfMTYnIGRhdGEtbmFtZT0nR3JvdXAgMTYnIHRyYW5zZm9ybT0ndHJhbnNsYXRlKDAgOTMuMTkxKSc+CiAgICAgIDxnCiAgICAgICAgaWQ9J0dyb3VwXzE1JwogICAgICAgIGRhdGEtbmFtZT0nR3JvdXAgMTUnCiAgICAgICAgdHJhbnNmb3JtPSd0cmFuc2xhdGUoMCAtOTMuMTkxKScKICAgICAgICBjbGlwUGF0aD0ndXJsKCNjbGlwLXBhdGgpJwogICAgICA+CiAgICAgICAgPGcKICAgICAgICAgIGlkPSdHcm91cF8xNCcKICAgICAgICAgIGRhdGEtbmFtZT0nR3JvdXAgMTQnCiAgICAgICAgICB0cmFuc2Zvcm09J3RyYW5zbGF0ZSgwLjAwMSAwLjAwNCknCiAgICAgICAgPgogICAgICAgICAgPHBhdGgKICAgICAgICAgICAgaWQ9J1BhdGhfMjEnCiAgICAgICAgICAgIGRhdGEtbmFtZT0nUGF0aCAyMScKICAgICAgICAgICAgZD0nTTg4Ljk3Miw4OC45N1YtOTMuMTg5SC05My4xOTFWODguOTdoNTUuNDJWMzguMjM5SDM0Ljg5NFY4OC45N1pNMzMuNy0zMC4zMjVBMzUuMTQyLDM1LjE0MiwwLDAsMS0xLjQ0LDQuODEzLDM1LjE0MSwzNS4xNDEsMCwwLDEtMzYuNTgyLTMwLjMyNSwzNS4xNDIsMzUuMTQyLDAsMCwxLTEuNDQtNjUuNDY5LDM1LjE0MywzNS4xNDMsMCwwLDEsMzMuNy0zMC4zMjUnCiAgICAgICAgICAgIHRyYW5zZm9ybT0ndHJhbnNsYXRlKDkzLjE5MSA5My4xODkpJwogICAgICAgICAgICBmaWxsPScjODJjZGQ5JwogICAgICAgICAgLz4KICAgICAgICA8L2c+CiAgICAgIDwvZz4KICAgIDwvZz4KICA8L3N2Zz4K'
            />
          </div>
        </Box>
        <Box
          width='37%'
          position='absolute'
          left='5%'
          bottom={0}
          onAnimationEnd={() => {
            setAnimations(prev => ({
              ...prev,
              l: prev.l === 'in' ? 'out' : false
            }))
          }}
          onMouseEnter={() => {
            !animations.l && setAnimations(prev => ({ ...prev, l: 'in' }))
          }}
          className={cx(
            classes.l,
            isIn(animations.l) && classes.lAnimation,
            isOut(animations.l) && classes.lAnimationBack
          )}
        >
          <div className='lLetter'>
            <img
              fadeIn='false'
              loading='eager'
              alt=''
              src={`/images/LLetter.svg`}
            />
          </div>
          <Box
            width='33%'
            position='absolute'
            right={0}
            top={0}
            classes={{ root: 'dot' }}
          >
            <img
              fadeIn='false'
              loading='eager'
              alt=''
              src='data:image/svg+xml;base64,ICA8c3ZnCiAgICB2ZXJzaW9uPScxLjEnCiAgICBpZD0nR3JvdXBfMTInCiAgICB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnCiAgICB4PScwcHgnCiAgICB5PScwcHgnCiAgICB2aWV3Qm94PScwIDAgMTA3LjcgMTA3LjcnCiAgPgogICAgPHBhdGgKICAgICAgaWQ9J1BhdGhfMTgnCiAgICAgIGNsYXNzTmFtZT0nc3QwJwogICAgICBmaWxsPScjRUJFQkUzJwogICAgICBkPSdNNTMuOSwxMDcuN2MyOS43LDAsNTMuOS0yNC4xLDUzLjktNTMuOWMwLDAsMCwwLDAsMEMxMDcuNywyNC4xLDgzLjYsMCw1My45LDBsMCwwCkMyNC4xLDAsMCwyNC4xLDAsNTMuOVMyNC4xLDEwNy43LDUzLjksMTA3LjdMNTMuOSwxMDcuNycKICAgIC8+CiAgPC9zdmc+Cgo='
            />
          </Box>
        </Box>
        <Box width='37%' position='absolute' right={0} bottom={0}>
          <img
            fadeIn='false'
            loading='eager'
            alt=''
            src={`/images/InstitutoLatino.svg`}
          />
        </Box>
      </Box>
    </Box>
  )
}
