import React from 'react'
import loadable from '@loadable/component'
import { StaticImage } from 'gatsby-plugin-image'
import { Box, Link, makeStyles, Typography } from '@material-ui/core'

import { Page } from '../components/Page'
import { useCoreData } from '../utils/useCoreData'
import { Headline } from '../components/Headline'
import { colors, respSectionPadding, sectionPadding } from '../utils/theme'
import { DesktopMainHome } from '../components/DesktopMainHome'
import { MobileMainHome } from '../components/MobileMainHome'
import { ID } from '../components/ID'
import { useMobile } from '../utils/useMobile'
import { useMediumScreen } from '../utils/useMediumScreen'
import { cx } from '../utils/cx'
import { richText } from '../utils/richText'
import { lyricsStyle, withLyrics } from '../utils/withLyrics'
import { carouselFontSize } from '../utils/carouselFontSize'
import { getWindow } from '../utils/getWindow'
import { Slider } from '../components/Slider'
const FullPageBrandsBanner = loadable(() =>
  import('../components/FullPageBrandsBanner')
)
const PublicationsSection = loadable(() =>
  import('../components/PublicationsSection')
)
const NewsletterSignUpSection = loadable(() =>
  import('../components/NewsletterSignUpSection')
)
const Divider = loadable(() => import('../components/Divider'))
const HorizontalHeadline = loadable(() =>
  import('../components/HorizontalHeadline')
)
const HalfHalfWrapper = loadable(() => import('../components/HalfHalfWrapper'))
const Container = loadable(() => import('../components/Container'))
const FullPage = loadable(() => import('../components/FullPage'))

export default function IndexPage () {
  return (
    <Page index>
      <HomepageContent />
    </Page>
  )
}

const useStyles = makeStyles(() => ({
  withStrong: {
    '& strong': {
      fontWeight: 600,
      display: 'block'
    }
  },
  image: {
    margin: 'auto',
    maxWidth: '100%'
  },
  smallHeadline: {
    fontSize: '24px'
  },
  whyData: {
    '&:not(:last-of-type)': {
      marginRight: '14px'
    }
  },
  small: {
    '& h5': {
      fontSize: '18px !important'
    }
  },
  ...lyricsStyle
}))

function HomepageContent () {
  const classes = useStyles()
  const mobile = useMobile()
  const mediumScreen = useMediumScreen()
  const {
    api: {
      headerHeight,
      data: { publications, homepage, our_work }
    }
  } = useCoreData()

  if (!headerHeight) return null
  if (!homepage) {
    const win = getWindow()
    return mobile || (win && win.innerWidth < 600) ? (
      <MobileMainHome headerHeight={headerHeight} />
    ) : (
      <DesktopMainHome headerHeight={headerHeight} />
    )
  }

  const {
    headline,
    sentence_carousel,
    who_are_we_title,
    who_are_we_description,
    read_more_about_us,
    why,
    why_data
  } = homepage

  const { what_we_do_title, what_we_do_text } = our_work

  function WhoWeAreSection () {
    return (
      <HorizontalHeadline
        title={richText(who_are_we_title)}
        content={
          <>
            {richText(who_are_we_description)}
            <Box pt='1.5rem' fontSize='17px' fontFamily='Lato'>
              <Link href='/about-us' underline='always'>
                {read_more_about_us}
              </Link>
            </Box>
          </>
        }
      />
    )
  }

  return (
    <>
      {mobile ? (
        <MobileMainHome
          headerHeight={headerHeight}
          headline={headline}
          readMore={read_more_about_us}
        />
      ) : (
        <DesktopMainHome
          headerHeight={headerHeight}
          headline={headline}
          readMore={read_more_about_us}
        />
      )}
      {mobile && (
        <>
          <Divider condensed />
          <NewsletterSignUpSection />
          <Divider condensed />
          <Container>
            <WhoWeAreSection />
          </Container>
        </>
      )}
      <FullPage bgcolor={colors.white}>
        <Container unwrap={mobile}>
          <Box py={`${mobile ? sectionPadding / 2 : sectionPadding}px`}>
            <ID id='our_team' />
            <HalfHalfWrapper
              leftBgColor={colors.lightGrey}
              rightBgColor={colors.yellow}
              leftContent={
                <Slider>
                  <div>
                    <StaticImage
                      alt=''
                      className={classes.image}
                      src='../../static/images/gifs/GIF_sm.png'
                    />
                  </div>
                  <div>
                    <StaticImage
                      alt=''
                      className={classes.image}
                      src='../../static/images/gifs/GIF2_sm.png'
                    />
                  </div>
                  <div>
                    <StaticImage
                      alt=''
                      className={classes.image}
                      src='../../static/images/gifs/GIF3_sm.png'
                    />
                  </div>
                  <div>
                    <StaticImage
                      alt=''
                      className={classes.image}
                      src='../../static/images/gifs/GIF4_sm.png'
                    />
                  </div>
                  <div>
                    <StaticImage
                      alt=''
                      className={classes.image}
                      src='../../static/images/gifs/GIF5_sm.png'
                    />
                  </div>
                  <div>
                    <StaticImage
                      alt=''
                      className={classes.image}
                      src='../../static/images/gifs/GIF6_sm.png'
                    />
                  </div>
                  <div>
                    <StaticImage
                      alt=''
                      className={classes.image}
                      src='../../static/images/gifs/GIF7_sm.png'
                    />
                  </div>
                  <div>
                    <StaticImage
                      alt=''
                      className={classes.image}
                      src='../../static/images/gifs/GIF8_sm.png'
                    />
                  </div>
                </Slider>
              }
              rightContent={
                <Slider interactable>
                  {sentence_carousel.map((s, i) => (
                    <Box
                      px={mobile ? '30px' : '70px'}
                      py='40px'
                      height='100%'
                      textAlign='center'
                      display='flex'
                      color={colors.black}
                      key={`home_slide_${i}`}
                      classes={{ root: mobile && classes.small }}
                    >
                      <Headline
                        variant={carouselFontSize(s)}
                        className={cx(
                          mediumScreen && classes.smallHeadline,
                          classes.lyrics
                        )}
                      >
                        {richText(withLyrics(s.text))}
                      </Headline>
                      <Box textAlign='center' mt='14px'>
                        <Typography variant='subtitle2' key={`${s}${i}`}>
                          {s.author}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Slider>
              }
            />
          </Box>
          {!mobile && <WhoWeAreSection />}
          {!mobile && <Divider />}
          <Container unwrap={!mobile}>
            {why ? (
              <HorizontalHeadline
                pb={`${mobile ? sectionPadding / 2 : sectionPadding}px`}
                title={<strong>{why}</strong>}
                content={
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems={mobile ? 'center' : ''}
                    flexDirection={mobile ? 'column' : 'row'}
                    className={classes.withStrong}
                    flexWrap='wrap'
                  >
                    {why_data.map((d, i) => (
                      <Box
                        mb={mobile ? '24px' : ''}
                        className={classes.whyData}
                        key={d.title + i}
                      >
                        <Typography variant='body1'>{d.title}</Typography>
                        <Typography variant='h3'>
                          <strong>{d.value}</strong>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                }
              />
            ) : (
              <Box
                mt={respSectionPadding(true) + 'px'}
                mb={respSectionPadding(mobile) + 'px'}
              >
                <ID id='what_we_do' />
                <HorizontalHeadline
                  paragraph
                  title={richText(what_we_do_title)}
                  content={richText(what_we_do_text)}
                />
              </Box>
            )}
          </Container>
        </Container>
      </FullPage>
      {publications && <PublicationsSection />}
      <Box mt={publications ? respSectionPadding(true) + 'px' : ''} display={mobile ? 'none' : ''}>
        {!publications && <Container>
          <Divider condensed />
        </Container>}
        <NewsletterSignUpSection withBottomPadding />
      </Box>
      <FullPageBrandsBanner />
    </>
  )
}