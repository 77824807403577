import { Box, Link, makeStyles } from '@material-ui/core'
import React from 'react'
import { AnimatedBanner } from '../components/AnimatedBanner'
import { colors } from '../utils/theme'
import { Headline } from './Headline'
import { useMediumScreen } from '../utils/useMediumScreen'
import { Container } from './Container'
import { richText } from '../utils/richText'

const useStyles = makeStyles(() => ({
  withStrong: {
    fontWeight: 600,
    '& strong': {
      fontWeight: 600
    }
  }
}))

export function DesktopMainHome ({ headerHeight, headline, readMore }) {
  const classes = useStyles()
  const mediumScreen = useMediumScreen()
  return (
    <Box position='relative'>
      <Box
        display='flex'
        position='absolute'
        top={0}
        left={0}
        height='100%'
        width='100%'
      >
        <Box bgcolor='white' width='47%' pb='35px' />
        <Box
          bgcolor={colors.black}
          minHeight={mediumScreen ? '60vw' : `${750 - headerHeight}px`}
          width='53%'
          pb='35px'
        />
      </Box>
      <Container withToolbar>
        <Box
          minHeight={mediumScreen ? '60vw' : `${750 - headerHeight}px`}
          width='100%'
          pb='30px'
        >
          <Box
            display='flex'
            minHeight={mediumScreen ? '60vw' : `${850 - headerHeight}px`}
          >
            <Box
              flexBasis='47%'
              display='flex'
              flexDirection='column'
              justifyContent='center'
              pr={mediumScreen ? 0 : '8%'}
              position='relative'
            >
              <Box
                fontSize={mediumScreen ? '.6rem' : ''}
                pr={mediumScreen ? '20px' : ''}
              >
                {Boolean(headline) && (
                  <Headline
                    variant={mediumScreen ? 'h4' : 'h4'}
                    className={classes.withStrong}
                  >
                    {richText(headline)}
                  </Headline>
                )}
                {Boolean(readMore) && (
                  <Box position='absolute' pt='2.5rem' fontSize='17px'>
                    <Link href='/about-us' underline='always'>
                      {readMore}
                    </Link>
                  </Box>
                )}
              </Box>
            </Box>
            <AnimatedBanner />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
